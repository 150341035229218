.chart-container {
    width: inherit;
    margin: 0 auto;
    padding: 20px;
    background-color: #34495e ; /* Dark background for the container */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }
  
  .chart-container h2 {
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
  }
  

  
